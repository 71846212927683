import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const Imprint = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h2 className="title gr-text-2 mb-9">Imprint</h2>
                  <p className="gr-text-8 mb-5">
                    We display our legal texts online in English. For other
                    languages or understanding problems, please contact
                    info@trustify.ch. Thanks.
                  </p>
                  <h3 className="gr-text-7 mb-8">
                    <a href="https://trustify.ch/">Back to startpage</a>
                  </h3>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-3">
                      Contact details
                    </h3>
                    <p className="gr-text-9">
                      NAVEST GmbH<br></br>
                      Gliserallee 144<br></br>
                      3902 Brig-Glis<br></br>
                      Switzerland<br></br>
                      <br></br>
                      info@trustify.ch<br></br>
                      +41 27 558 09 09<br></br>
                      <br></br>
                      Company No. (UID): CHE-237.045.759
                    </p>
                  </div>
                  <div className="single-block mb-13">
                    <h3 className="gr-text-8 font-weight-bold">Disclaimer</h3>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        No responsibility is taken for the correctness,
                        accuracy, timeliness, reliability and completeness of
                        information.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Liability claims regarding any material or immaterial
                        damage caused by the access, use or non-use of any
                        information provided, arisen due to misuse of the
                        connection or due to technical faults, etc. will
                        therefore be rejected as far as legally permissible.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Despite careful control of the content, we assume no
                        liability for the content of external links and on
                        customers Trustify pages. The operators of the linked
                        pages are solely responsible for their content.
                        Similarly, customers are responsible for their own
                        content on their Trustify profile pages. The use of or
                        access to this website is at the visitor's own risk.
                      </li>
                    </ul>
                    <p className="gr-text-9 pb-5">
                      You will find more about the disclaimer and further
                      regulations in the privacy policy:
                      https://trustify.ch/privacy-policy and in the terms and
                      conditions: https://trustify.ch/terms-conditions
                    </p>
                  </div>
                  <div className="single-block mb-11">
                    <h3 className="gr-text-8 font-weight-bold pb-5">
                      Copyright
                    </h3>
                    <p className="gr-text-9">
                      The adoption and use of the texts, images, video,
                      representations and data is prohibited unless NAVEST GmbH
                      has given its consent. The logo and color compositions may
                      not be used elsewhere.
                    </p>
                    <p className="gr-text-9">
                      The copyrights and all other rights to content, images,
                      videos, photos or other files on the website belong
                      exclusively to NAVEST GmbH or the specifically named
                      copyright holders. For the reproduction of any elements,
                      the written consent of the copyright holders must be
                      obtained in advance.
                    </p>
                    <p className="gr-text-9">
                      Anyone who commits a copyright infringement without the
                      consent of the respective rights holder may be liable to
                      prosecution and possibly to compensation for damages.
                    </p>
                  </div>
                  <div className="single-block mb-13">
                    <h3 className="gr-text-8 font-weight-bold">
                      Further regulations & information
                    </h3>
                    <ul className="list-unstyled">
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        We cannot guarantee the security of data transmission on
                        the Internet; in particular, there is a risk of access
                        by third parties when data is transmitted.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        The European Commission provides a platform for
                        out-of-court online dispute resolution (ODR platform).
                        We do not participate.
                      </li>
                      <li className="mt-7">
                        <i
                          className="rounded-circle bg-dark d-inline-block mr-5"
                          css={`
                            width: 9px;
                            height: 9px;
                          `}
                        ></i>
                        Should individual provisions or formulations of this
                        imprint be or become invalid, this shall not affect the
                        content or validity of the remaining provisions. In the
                        event of disputes, Swiss law applies exclusively and the
                        place of jurisdiction is Switzerland, Brig.
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Imprint;
